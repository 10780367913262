@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: poppins;
}

.no-animation *,
.no-animation *::before,
.no-animation *::after {
  animation: none !important;
  transition: none !important;
}

.fullHeightVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  height: 107vh;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}
.text-outline {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#logo {
  animation: fill 2s ease forwards 1.5s;
}

#logo path:nth-child(1) {
  stroke-dasharray: 449px;
  stroke-dashoffset: 449px;
  animation: line-anim 2s ease forwards 0.1s;
}

#logo path:nth-child(2) {
  stroke-dasharray: 239px;
  stroke-dashoffset: 239px;
  animation: line-anim 2s ease forwards 0.2s;
}
#logo path:nth-child(3) {
  stroke-dasharray: 422.5px;
  stroke-dashoffset: 422.5px;
  animation: line-anim 2s ease forwards 0.3s;
}
#logo path:nth-child(4) {
  stroke-dasharray: 506px;
  stroke-dashoffset: 506px;
  animation: line-anim 2s ease forwards 0.4s;
}
#logo path:nth-child(5) {
  stroke-dasharray: 429.42px;
  stroke-dashoffset: 429.42px;
  animation: line-anim 2s ease forwards 0.5s;
}
#logo path:nth-child(6) {
  stroke-dasharray: 493px;
  stroke-dashoffset: 493px;
  animation: line-anim 2s ease forwards 0.6s;
}
#logo path:nth-child(7) {
  stroke-dasharray: 433px;
  stroke-dashoffset: 433px;
  animation: line-anim 2s ease forwards 0.7s;
}
#logo path:nth-child(8) {
  stroke-dasharray: 422px;
  stroke-dashoffset: 422px;
  animation: line-anim 2s ease forwards 0.8s;
}
#logo path:nth-child(9) {
  stroke-dasharray: 239.32px;
  stroke-dashoffset: 239.32px;
  animation: line-anim 2s ease forwards 0.9s;
}
#logo path:nth-child(10) {
  stroke-dasharray: 668px;
  stroke-dashoffset: 668px;
  animation: line-anim 2s ease forwards 1s;
}
#logo path:nth-child(11) {
  stroke-dasharray: 506px;
  stroke-dashoffset: 506px;
  animation: line-anim 2s ease forwards 1.1s;
}
#logo path:nth-child(12) {
  stroke-dasharray: 314.94px;
  stroke-dashoffset: 314.94px;
  animation: line-anim 2s ease forwards 1.2s;
}
#logo path:nth-child(13) {
  stroke-dasharray: 515.26px;
  stroke-dashoffset: 515.26px;
  animation: line-anim 2s ease forwards 1.3s;
}
/* logo two */
#logoTwo {
  animation: fill 2s ease forwards 1.5s;
}

#logoTwo path:nth-child(1) {
  stroke-dasharray: 514.77px;
  stroke-dashoffset: 514.77px;
  animation: line-anim 2s ease forwards 1.4s;
}

#logoTwo path:nth-child(2) {
  stroke-dasharray: 330.95px;
  stroke-dashoffset: 330.95px;
  animation: line-anim 2s ease forwards 1.3s;
}
#logoTwo path:nth-child(3) {
  stroke-dasharray: 495px;
  stroke-dashoffset: 495px;
  animation: line-anim 2s ease forwards 1.2s;
}
#logoTwo path:nth-child(4) {
  stroke-dasharray: 677.91px;
  stroke-dashoffset: 677.91px;
  animation: line-anim 2s ease forwards 1.1s;
}
#logoTwo path:nth-child(5) {
  stroke-dasharray: 255.32px;
  stroke-dashoffset: 255.32px;
  animation: line-anim 2s ease forwards 1s;
}
#logoTwo path:nth-child(6) {
  stroke-dasharray: 433.27px;
  stroke-dashoffset: 433.27px;
  animation: line-anim 2s ease forwards 0.9s;
}
#logoTwo path:nth-child(7) {
  stroke-dasharray: 433.77px;
  stroke-dashoffset: 433.77px;
  animation: line-anim 2s ease forwards 0.8s;
}
#logoTwo path:nth-child(8) {
  stroke-dasharray: 509.76px;
  stroke-dashoffset: 509.76px;
  animation: line-anim 2s ease forwards 0.7s;
}
#logoTwo path:nth-child(9) {
  stroke-dasharray: 495px;
  stroke-dashoffset: 495px;
  animation: line-anim 2s ease forwards 0.6s;
}
#logoTwo path:nth-child(10) {
  stroke-dasharray: 433.77px;
  stroke-dashoffset: 433.77px;
  animation: line-anim 2s ease forwards 0.5s;
}
#logoTwo path:nth-child(11) {
  stroke-dasharray: 268.37px;
  stroke-dashoffset: 268.37px;
  animation: line-anim 2s ease forwards 0.4s;
}
#logoTwo path:nth-child(12) {
  stroke-dasharray: 449.73px;
  stroke-dashoffset: 449.73px;
  animation: line-anim 2s ease forwards 0.3s;
}
.equal-shadow {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}
.horizontal-slider {
  width: 200px;
  height: 25px;
}

.thumb {
  height: 15px;
  line-height: 20px;
  width: 15px;
  margin-top: 5px;
  text-align: center;
  background-color: #9b111e;
  color: #9b111e;
  border-radius: 50%;
  cursor: pointer;
}

.track {
  top: 10px;
  bottom: 0;
  background: #f0c7cd;
  height: 5px;
  width: 100%;
}

/* Hide the middle track between the two thumbs */
.horizontal-slider .track-2 {
  background: transparent;
  display: none;
  pointer-events: none;
}

.horizontal-slider .track-1 {
  background: transparent;
  display: none;
  pointer-events: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.button {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

.button:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.App {
  margin-left: 25%;
  margin-top: 5%;
  margin-right: 25%;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}
